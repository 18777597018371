import React from 'react';

import StarIcon from '../../assets/images/star-icon.png'
import HowItsWork from '../../assets/images/how-its-work.png'

const HowItWork = () => {
    return (
        <section id="howitworks" className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="image" />
                                How It's Work
                            </span>
                            <h2>The AISHA Process</h2>
                            <p>Purpose-Built. Clinically Aligned. AI-Powered.</p>
                            {/* <div className="inner-box"> */}
                            {/* <div className="single-item"> */}
                            {/* <div className="count-box">
                                        1
                                    </div> */}
                            {/* <h3>Data for All Your People</h3> */}
                            <p>At AISHA, we don’t believe in one-size-fits-all technology. Every solution we build is purpose-built for healthcare—designed in close collaboration with clinicians, administrators, and compliance experts to solve real problems, in real workflows.</p>
                            <p>From intelligent automation to regulatory-grade AI tools, our process begins with deep discovery, continues through clinical validation, and ends with secure, scalable deployments that empower your teams and protect your patients.</p>
                            {/* </div> */}
                            {/* <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Data for All Your People</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>A New Breed of AI</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Analytics Business</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div> */}
                            {/* </div> */}
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={HowItsWork} alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork;
import React from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'gatsby'
import Loadable from '@loadable/component'
import aidr from "../../assets/images/aishacontent/aidr.jpeg"
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))


const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const MainBanner = () => {
    // Popup Video
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    // Owl Carousel
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            {/* <ModalVideo
                channel='youtube'
                isOpen={!isOpen}
                videoId='bk7McNUjWgw'
                onClose={() => setIsOpen(!isOpen)}
            /> */}

            {display ? <OwlCarousel
                className="home-slides owl-carousel owl-theme"
                {...options}
            >
                <div id="home" className="main-banner-item item-mb1">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="banner-item-content">
                                    <span className="sub-title">Welcome</span>
                                    <h1>Harnessing AI to Streamline Healthcare and Pharmacy Benefit Management</h1>
                                    <p>Unlocking Efficiency and Accuracy in Medical Benefits with AI-Powered Solutions</p>

                                    {/* <div className="btn-box">
                                        <Link to="/about-us" className="default-btn">
                                            <i className="flaticon-right"></i>
                                            About Us <span></span>
                                        </Link>

                                        <Link
                                            to="#play-video"
                                            onClick={e => { e.preventDefault(); openModal() }}
                                            className="video-btn popup-youtube"
                                        >
                                            <i className="flaticon-google-play"></i> Watch Video
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-banner-item item-mb3">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="banner-item-content">
                                    <span className="sub-title">Welcome</span>
                                    <h1>The Future of Healthcare and Pharmacy Benefits</h1>
                                    <p>Exploring Innovations and Strategies for Improving Patient Care and Reducing Costs</p>

                                    {/* <div className="btn-box">
                                        <Link to="/about-us" className="default-btn">
                                            <i className="flaticon-right"></i>
                                            About Us <span></span>
                                        </Link>

                                        <Link
                                            to="#play-video"
                                            onClick={e => { e.preventDefault(); openModal() }}
                                            className="video-btn popup-youtube"
                                        >
                                            <i className="flaticon-google-play"></i> Watch Video
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel> : ''}
        </React.Fragment>
    )
}

export default MainBanner;